export const GROUP_ALL = {
  id: null,
  name: '全部',
  image_number: 0,
}

export const GROUP_RECYCLE_BIN = {
  id: -1,
  name: '回收站',
  image_number: 0,
}

export const ORDER = {
  asc: 'asc',
  desc: 'desc',
}
